<template>
  <van-pull-refresh v-model="isLoading" pulling-text="Tarik ke bawah untuk refresh..." loosing-text="Lepaskan untuk refresh..."  success-text="Refresh Berhasil" @refresh="onRefresh">
    <div class="authentication">
      <BaseHeaderBar title="Verifikasi Data" :showCustomerService="false" backBtnType="2" :showBackButton="true" color="#FFF" bgColor="#B83AF3" />
      <div class="content">
        <div class="authentication-list">
          <div @click="goIdentity">
            <img class="icon" src="@/assets/img/authentication-icon1.png" />
            <p>Informasi Identitas</p>
            <img class="badge" :src="require(`@/assets/img/authentication-badge${authInfo.iddrentityInfoStatus ? 1 : 0}.png`)" />
          </div>
          <div @click="goBasicInfo">
            <img class="icon" src="@/assets/img/authentication-icon2.png" />
            <p>Infomasi Dasar</p>
            <img class="badge" :src="require(`@/assets/img/authentication-badge${authInfo.indrformationStatus ? 1 : 0}.png`)" />
          </div>
          <div @click="goBankInfo">
            <img class="icon" src="@/assets/img/authentication-icon3.png" />
            <p>Informasi Bank</p>
            <img class="badge" :src="require(`@/assets/img/authentication-badge${authInfo.badrnkStatus ? 1 : 0}.png`)" />
          </div>
        </div>
        <van-checkbox v-model="checked">
          <div class="privacy">Mengkonfirmasi dan Menyetujui "<a @click.stop="goAgreement">Perjanjian Pinjaman</a>"</div>
          <template #icon="props">
            <img class="check-icon" :src="require(`@/assets/img/checked-${props.checked ? '' : 'no-'}icon.png`)" />
          </template>
        </van-checkbox>
      </div>
      <button type="button" class="confirm-btn" @click="onSubmit">Kirim</button>
      <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay>
        <img class="location-tip" src="@/assets/img/location-tip.png" />
        <div class="location-confirm" @click="onLocationConfirm">
          <img src="@/assets/img/location-confirm.png" />
        </div>
      </van-dialog>
      <van-dialog v-model="denyVisible" :showConfirmButton="false" close-on-click-overlay>
        <img class="location-deny" src="@/assets/img/location-deny.png" />
        <div class="location-confirm" @click="onCloseDialog('denyVisible')">
          <img src="@/assets/img/location-confirm.png" />
        </div>
      </van-dialog>
    </div>
    <template #loading>
      <span></span>
    </template>
  </van-pull-refresh>
</template>

<script>
import Vue from 'vue'
import { Checkbox, Toast, PullRefresh, Dialog } from 'vant'
import request from '@/utils/request'
import webviewBack from '@/mixins/webviewBack'
import dialogVisible from '@/mixins/dialogVisible'

Vue.use(Checkbox)
Vue.use(Toast)
Vue.use(PullRefresh)

export default {
  name: 'Authentication',
  mixins: [webviewBack, dialogVisible],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      isLoading: false,
      authInfo: {},
      denyVisible: false,
      checked: true
    }
  },
  created () {
    this.getAuthInfo()
  },
  methods: {
    getAuthInfo (isRefresh) {
      request.post('audrthIdentityPage')
        .then(res => {
          console.log('信息认证', res.audrthList, JSON.stringify(res.audrthList))
          this.authInfo = res.audrthList
          isRefresh && (this.isLoading = false)
        })
    },
    isReportLocation () {
      return request.post('posisyonnanEstime')
    },
    goIdentity () {
      if (this.authInfo.iddrentityInfoStatus) {
        Toast('Anda sudah verifikasi data identitas diri')
        return
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/identity',
        query: {
          amount
        }
      })
    },
    goBasicInfo () {
      if (this.authInfo.indrformationStatus) {
        Toast('Anda sudah verifikasi data informasi dasar')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/basicInfo',
        query: {
          amount
        }
      })
    },
    goBankInfo () {
      if (this.authInfo.badrnkStatus) {
        Toast('Anda sudah verifikasi data bank')
        return
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      const { amount } = this.$route.query
      this.$router.push({
        path: '/bankInfo',
        query: {
          type: 'auth',
          amount
        }
      })
    },
    goAgreement () {
      const { amount } = this.$route.query
      this.$router.push({
        path: '/agreement',
        query: {
          amount
        }
      })
    },
    validate () {
      if (!this.checked) {
        Toast('Mengkonfirmasi dan Menyetujui "Perjanjian Pinjaman"')
        return false
      }
      if (!this.authInfo.iddrentityInfoStatus) {
        Toast('Silakan verifikasi data identitas diri terlebih dahulu')
        return false
      }
      if (!this.authInfo.indrformationStatus) {
        Toast('Silakan verifikasi informasi dasar terlebih dahulu')
        return false
      }
      if (!this.authInfo.badrnkStatus) {
        Toast('Silakan verifikasi data bank terlebih dahulu')
        return false
      }
      return true
    },
    onLocationConfirm () {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
          console.log('position', position)
          this.reportLocation(position.coords).then(() => {
            this.submitOrder()
          })
        }, (err) => {
          console.log(err.message, err)
          switch (err.code) {
            case err.PERMISSION_DENIED:
              this.denyVisible = true
              this.hasDeny = true
              break
          }
        })
      }
      this.onCloseDialog()
    },
    submitOrder () {
      if (this.flag) return
      this.flag = true
      const { amount } = this.$route.query
      const postData = {
        apdrplicationAmount: amount,
        prdromotionChannel: 'AppStore'
      }
      request.post('sudrbmitloanOrder', postData)
        .then(() => {
          this.$router.push('/')
        })
        .finally(() => {
          this.flag = false
        })
    },
    onSubmit () {
      if (!this.validate()) return
      this.isReportLocation().then(res => {
        if (res.whetherGrab === 1) {
          if (this.hasDeny) {
            this.denyVisible = true
          } else {
            this.visible = true
          }
        } else {
          this.submitOrder()
        }
      })
    },
    onRefresh () {
      this.getAuthInfo(true)
    },
    reportLocation (data = {}) {
      console.log(typeof data, data, 'location')
      const { longitude = 0, latitude = 0, position = '', address = '', country = '', provinces = '', city = '', county = '', street = '' } = data
      const postData = {
        usdrerLongitude: longitude,
        usdrerLatitude: latitude,
        usdrerPositioning: position,
        usdrerAddress: address,
        usdrerCountries: country,
        usdrerProvinces: provinces,
        usdrerCity: city,
        usdrerCounty: county,
        usdrerStreet: street,
        crdrawl_source: 1
      }
      return request.post('grdrabLocation', postData)
    }
  }
}
</script>

<style scoped lang="less">
.authentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #F5E2FD;
}

.content {
  height: 760px;
  background: url(../assets/img/authentication-bg.png) no-repeat;
  background-size: 750px 760px;
}

.authentication-list {
  display: flex;
  flex-wrap: wrap;
  padding: 232px 25px 0;

  & > div {
    position: relative;
    width: 333px;
    height: 204px;
    box-shadow: 0 3px 14px 3px rgba(0,0,0,.07);
    background-color: #FFF;

    &:nth-child(n+3) {
      margin-top: 44px;
    }

    &:nth-child(even) {
      margin-left: 34px;
    }

    .icon {
      margin: 75px auto 17px;
    }

    &:nth-child(1) .icon {
      width: 82px;
      height: 54px;
    }

    &:nth-child(2) .icon {
      width: 67px;
      height: 62px;
    }

    &:nth-child(3) .icon {
      width: 81px;
      height: 61px;
    }

    p {
      line-height: 42px;
      font-size: 30px;
      color: #333;
      text-align: center;
    }

    .badge {
      position: absolute;
      top: 10px;
      right: 13px;
      width: 205px;
      height: 35px;
    }
  }
}

.check-icon {
  width: 24px;
  height: 24px;
  margin-top: 12px;
  margin-left: 25px;
}

.privacy {
  line-height: 33px;
  margin-top: 12px;
  margin-left: 5px;
  font-size: 24px;
  color: #333;

  a {
    color: #333;
    text-decoration: underline;
  }
}

.confirm-btn {
  .submit-btn;
  width: 690px;
  margin-top: 87px;
}

.location-tip {
  width: 625px;
  height: 929px;
  margin: 30px auto 0;
}

.location-confirm {
  width: 578px;
  height: 93px;
  margin: 30px auto;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }
}

.location-deny {
  width: 624px;
  height: 353px;
  margin: 30px auto;
}
</style>
